(function ($) {
    "use strict";

    $.widget("lms.videoPlayer", {
        options: {},
        state:{},
        availabeContents:[
            {
                id: "ca582d7f",
                begin: 5000,
                contentType: 0,
                data: "{\"answers\":[{\"id\":\"411703f5-bd62-44af-bbc1-76a621ec9fce\",\"correct\":false,\"text\":\"Yanıt 1\"},{\"id\":\"d8f3b72f-0463-4fd9-98d4-b9cdfaee34e9\",\"correct\":false,\"text\":\"Yanıt 2\"},{\"id\":\"886468b4-3d6f-4607-9d40-9e9fdafd5e7a\",\"correct\":true,\"text\":\"Yanıt 3\"},{\"id\":\"7438dca8-31c1-4e34-b22b-64455838c194\",\"correct\":false,\"text\":\"Yanıt 4\"}],\"id\":\"52621c03-eeb6-4bfb-b189-285eba2995f8\",\"text\":\"Soru 1\"}"
            },
            {
                id: "ca582duu",
                begin: 9000,
                contentType: 0,
                data: "{\"answers\":[{\"id\":\"411703f5-bd62-44af-bbc1-76a621ec9fxe\",\"correct\":false,\"text\":\"Y 1\"},{\"id\":\"d8f3b72f-0463-4fd9-98d4-b9cdfaee34e4\",\"correct\":false,\"text\":\"Y 2\"},{\"id\":\"886468b4-3d6f-4607-9d40-9e9fdafd5eaa\",\"correct\":true,\"text\":\"Y 3\"},{\"id\":\"7438dca8-31c1-4e34-b22b-64455838c1y4\",\"correct\":false,\"text\":\"Y 4\"}],\"id\":\"52621c03-eeb6-4bfb-b189-285eba2995f8\",\"text\":\"Soru 2\"}"
            }
        ],
        viewedContents:[],
        videoElem:undefined,
        videoContainerElem:undefined,
        _create: function(){
            var options = this.options;
            options.id = this.element.attr('id');
            options.attemptId = this.element.attr('attempt');
            options.contentId = this.element.attr('content-id');
            options.videoUrl = this.element.attr('video-url');
            options.apiUrl = this.element.attr('api-url');
            options.stateTracker = this.element.attr('state-tracker');
            options.contentManager = this.element.attr('content-manager');
            var widgetEl = this._generateWidgetElement();
            widgetEl.append(this._generateFullscreenIcon());
            widgetEl.append(this._generatePlayIcon())
            this.element.append(widgetEl);
        },
        _getMediaState:function(){
            var base = this;
            var stateTracker = base.options.stateTracker;
            var content = base.options.contentId;
            var attempt = base.options.attemptId;
            var apiUrl = base.options.apiUrl;
            if(content == undefined || attempt == undefined || apiUrl == undefined || stateTracker == undefined){
                return false;
            }
            stateTracker = apiUrl+stateTracker;
            stateTracker += "/get?attemptId={attempt}";
            stateTracker = stateTracker.replace("{content}",content);
            stateTracker = stateTracker.replace("{attempt}",attempt);
            var bearerToken = sessionStorage.getItem("token");
            if(stateTracker != null && stateTracker != ""){
                $.ajax({
                    url:stateTracker+"/get",
                    type:'GET',
                    dataType:'json',
                    success:function(response){
                        var data = JSON.parse(response.content.data);
                        if(data !== undefined && data.time !== undefined && data.time != 0){
                            videoElem.currentTime = data.time;
                        }
                    },error:function(){

                    }
                })
            } 
        },
        _getVideoContents:function(){
            var base = this;

        },

        _generateWidgetElement : function() {
            var base = this;
            var options = this.options;
            var availabeContents = this.availabeContents;
            var viewedContents = this.viewedContents;

            var videoContainer = document.createElement("div");
            videoContainer.style.position = "relative";
            videoContainer.style.width = "100%";
            videoContainer.style.height = "100%";

            var videoEl = document.createElement("video");
            this.videoElem = videoEl;
            videoEl.autoplay = this.element.attr("autoplay") ?? false;
            videoEl.controls = false;
            videoEl.loop = this.element.attr("loop") ?? false;
            videoEl.style.width = "100%";
            videoEl.style.height = "100%";
            base._updateMediaState();
            videoEl.ontimeupdate = function (ev) {
                var currentTime = Math.round(videoEl.currentTime * 1000);
                if (availabeContents.length > 0 && availabeContents[0].begin < currentTime) {
                    var content = availabeContents.shift()
                    viewedContents.push(content);
                    console.log("triggered");
                    base._triggerRequiredContentEvent(content);
                }
            videoEl.onplay = function(ev){
                //console.log("playing");
                //base._updateMediaState();
            }
            }
            videoEl.src = options.videoUrl;

            videoContainer.append(videoEl);

            this.videoContainerElem = videoContainer;
            return videoContainer;
        },
        _triggerRequiredContentEvent: function(content){
            this._trigger("requiredcontent",undefined,{
                content:content
            })
        },
        _generatePlayIcon : function(){
            var base = this;
            var playIcon = document.createElement("i");
            playIcon.classList.add("bi", "bi-play-fill");
            playIcon.style.color = "white";
            playIcon.style.zIndex = "3";
            playIcon.style.fontSize = "4em";
            playIcon.style.left = "5px";
            playIcon.style.top = "-15px";
            playIcon.style.cursor = "pointer";
            playIcon.style.position = "absolute";
            playIcon.onclick = function(){
                base.videoElem.play();
            }
            return playIcon;
        },
        _updateMediaState:function(){
            console.log("run updateMediaState")
            var base = this;
            var mediaStateUpdater = setInterval(()=>{
                var videoElem = base.videoElem;
                console.log(videoElem);
                console.log(videoElem.currentTime);
                console.log(videoElem.paused);
                console.log(videoElem.ended);
                if(videoElem != undefined && videoElem.currentTime > 0 && videoElem.paused == false && videoElem.ended == false){
                    var mediaState = {
                        time:base.videoElem.currentTime
                    };
                    var stateTracker = base.options.stateTracker;
                    var content = base.options.contentId;
                    var attempt = base.options.attemptId;
                    var apiUrl = base.options.apiUrl;
                    if(content == undefined || attempt == undefined || apiUrl == undefined || stateTracker == undefined){
                        return false;
                    }
                    stateTracker = apiUrl+stateTracker;
                    stateTracker += "/set?attemptId={attempt}";
                    stateTracker = stateTracker.replace("{content}",content);
                    stateTracker = stateTracker.replace("{attempt}",attempt);
                    var bearerToken = sessionStorage.getItem("token");
                    if(stateTracker != null && stateTracker != ""){
                        $.ajax({
                            url:stateTracker,
                            type:'POST',
                            data:mediaState,
                            dataType:'json',
                            success:function(response){
                                var data = JSON.parse(response.content.data);
                                if(data !== undefined && data.time !== undefined && data.time != 0){
                                    videoElem.currentTime = data.time;
                                }
                            },error:function(){
        
                            }
                        })
                    } 
                }
            },10000)


        },
        _generateFullscreenIcon : function(){
            var baseDiv = this.videoContainerElem;
            var fullScreenIcon = document.createElement("i");
            fullScreenIcon.classList.add("bi", "bi-fullscreen");
            fullScreenIcon.style.color = "white";
            fullScreenIcon.style.zIndex = "3";
            fullScreenIcon.style.fontSize = "3em";
            fullScreenIcon.style.right = "15px";
            fullScreenIcon.style.top = "0px";
            fullScreenIcon.style.cursor = "pointer";
            fullScreenIcon.style.position = "absolute";
            fullScreenIcon.onclick = function(){
                if (document.fullscreenElement != null) {
                    if (document.exitFullscreen) {
                        document.exitFullscreen();
                    } else if (document.webkitExitFullscreen) {
                        document.webkitExitFullscreen();
                    } else if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    } else if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                } else {
                    if (baseDiv.requestFullscreen) {
                        baseDiv.requestFullscreen();
                    } else if (baseDiv.mozRequestFullScreen) {
                        baseDiv.mozRequestFullScreen();
                    } else if (baseDiv.webkitRequestFullscreen) {
                        baseDiv.webkitRequestFullscreen();
                    } else if (baseDiv.msRequestFullscreen) {
                        baseDiv.msRequestFullscreen();
                    }
                }
            }
            return fullScreenIcon;
        }
    })
    $('.lms-video-player').videoPlayer();
})(jQuery);